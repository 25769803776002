<template>
    <div class="overlay">
        <div class="modal-content">
    <div class="modal-inner">
        <div class="close-btn" id="js-close-btn"><i class="fas fa-times"></i></div>
        <div class="modal-ttl">プライバシーポリシー</div>
        <dl>
            <dt>個人情報保護に関する基本方針【プライバシーポリシー】</dt>
            <dd>
                <p>
                    当社は、個人情報の保護の重要性に鑑み、また、建築資材販売業に対する社会の信頼をより向上させるため、
                    個人情報の保護に関する法律(個人情報保護法)その他の関連法令・ガイドライン等を遵守して、個人情報を適正に取扱うとともに、安全管理について適切な措置を講じます。
                </p>
                <p>
                    当社は、個人情報の取扱いが適正に行われるように従業者への教育・指導を徹底し、適正な取扱いが行われるよう取組んでまいります。<br>
                    また、個人情報の取扱いに関する苦情・ご相談に迅速にご対応し、当社の個人情報の取扱いおよび安全管理に係る適切な措置については、適宣見直し、改善いたします。
                </p>
            </dd>
        </dl>
        <dl>
            <dt>個人情報の取得</dt>
            <dd>
                <p>
                    当社は、業務上必要な範囲内で、かつ、適法で公正な手段により個人情報を取得します。<br>
                    個人情報の利用目的
                </p>
                <p>
                    当社は、建築資材販売業務により取得した個人情報を、当該業務の遂行に必要な範囲内で利用します。それら以外の他の目的に利用することはありません。<br>
                    当社における具体的な個人情報の利用目的は次のとおりです。
                </p>
            </dd>
            <dd>
            <p>
                当社が建築資材販売業務およびこれらに付帯・関連するサービスの提供<br>
                上記の利用目的を変更する場合には、その内容をご本人に対し、原則として書面などにより通知し、または当社のホームページ<a href="https://www.higashino.co.jp">[https://www.higashino.co.jp]</a>等により公表します。
            </p>
            </dd>
        </dl>
        <dl>
            <dt>個人データの安全管理措置</dt>
            <dd>当社は、取扱う個人データの漏えい、滅失またはき損の防止その他の個人データの安全管理のため、安全管理に関する取扱規程等の整備および実施体制の整備等、十分なセキュリティ対策を講じるとともに、利用目的の達成に必要とされる正確性・最新性を確保するために適切な措置を講じています。</dd>
            
        </dl>
        <dl>
            <dt>個人データの第三者への提供</dt>
            当社は、個人データを第三者に提供するにあたり、以下の場合を除き、ご本人の同意なく第三者に個人データを提供しません。
            <dd>
                <p>
                    (1) 法令に基づく場合<br>
                    (2) 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br>
                    (3) 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、公衆衛生の向上または児童の健全な育成の推進のために特に必要があるとき<br>
                    (4) 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                </p>
            </dd>
            <dt>センシティブ情報のお取扱い</dt>
            <dd>
                <p>
                    当社は、政治的見解、信教(宗教、思想および信条をいいます。)、労働組合への加盟、人種および民族、門地および本籍地、
                    保健医療および性生活ならびに犯罪歴に関する個人情報(以下、「センシティブ情報」といいます。)を次に掲げる場合を除くほか、取得、利用または第三者提供を行いません。<br>
                    (1) 法令に基づく場合<br>
                    (2) 人の生命、身体または財産の保護のために必要がある場合<br>
                    (3) 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合<br>
                    (4) 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合
                </p>
            </dd>
        </dl>
        <dl>
            <dt>当社に対するご照会</dt>
            <dd>
                <p>
                    下記お問い合わせ窓口にお問い合わせください。<br>
                    ご照会者がご本人であることをご確認させていただいたうえで、対応させていただきますので、あらかじめご了承願います。<br>
                    (当社からのＥメール、ダイレクトメール等による新商品・サービスのご案内について、ご希望されない場合は、下記のお問い合わせ先までお申し出ください。)<br>
                </p>
                <p>
                    お問い合わせ先<br>
                    代理店： 大阪市住吉区苅田8-7-20 株式会社東野材木店<br>
                    電話： 06-6696-7551 受付時間10：00～17：00<br>
                    電子メール： お問い合わせフォーム<br>
                    ホームページ： <a href="https://www.higashino.co.jp/">https://www.higashino.co.jp/</a>
                </p>
            </dd>
        </dl>
    </div>
        </div>
    </div>

</template>


<style scoped>
/* オーバーレイ */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);  
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  z-index: 99;
}

/* activeクラスのついたオーバーレイ */
.overlay.active {
  opacity: 1;
  visibility: visible;
}

/* モーダルウィンドウ */
.modal-content {
  max-width: 800px;
  width: 86%;
  padding: 4% 3%;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  margin: 0;
}

.modal-content.active {
  opacity: 1;
  visibility: visible;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
}

.modal-inner dl {
    margin-bottom: 15px;
}

.modal-inner dt {
    font-weight: bold;
    margin-bottom: 3px;
}

.modal-inner dd p{
    margin-bottom: 10px;
}


.modal-ttl {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}



@media screen and ( max-width:480px ) { 

    .modal-content {
        padding: 5%;
        width: 90%;
    }

}
</style>


