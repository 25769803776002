<template>
  <h4 class="title is-4 has-text-centered has-background-dark has-text-white py-4 mt-6">対処療法から予防再生療法へ</h4>

  <p class="has-text-centered my-4 py-4 is-size-4 lh-6">
    「常に若々しく」<br>
      木材劣化の改善と長期保存処理は<br class="is-hidden-tablet">将来の大出費も防ぎます。
  </p>
      <div v-for="(item, index) in CONTENTS" :key="index">
        <div class="">
          <figure class="image">
            <img class="object-fit-contain" :src="item.src" :alt="item.alt">
          </figure>
        </div>
      </div>
</template>

<script>
const CONTENTS = [
  {
    "src": "./static_img/chooseReason_img.jpg",
    // "alt": "写真です",
  }
];

export default {
  name: 'parts-about-reason',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>

@media all and (max-width:480px) {
  .is-size-4 {
    font-size: 1rem !important;
  }

  .lh-6 {
    line-height: 1.6 !important;
  }

  .title {
    margin-bottom: 0 !important;
  }
}
</style>
