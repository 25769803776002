<template>
  <div class="columns is-gapless" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column mt-6n" >
      <!-- <a href="https://youtu.be/q6BVTV3ZhI0" target="_blank" rel="noopener noreferrer">
        <figure class="image is-4by3">
          <img :src="item.src" :alt="item.alt">
        </figure>
        <p class="has-text-theme-red m-1">大阪市 大宮まぶね保育園さま 屋外木製遊具 施工動画を見る</p>
      </a> -->
      <div class="video-area">
        <iframe src="https://www.youtube.com/embed/hAapOi9hGws" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
    </div>
    <div class="column has-background-white">
      <div class="content py-2npm run build px-6 m-6 p-4-mobile m-4-mobile">
        <p class="has-text-theme-red is-size-9 has-text-weight-bold mb-2">{{item.caption}}</p>
        <p class="is-size-2 mincho mb-5" v-html="item.subtitle"></p>
        <p class="title is-5 lh-4_3">{{item.title}}</p>
        <p class="title is-5 lh-4_3">{{item.title2}}</p>
        <p class="title is-5 mb-5 lh-4_3">{{item.title3}}</p>
        <!-- <p class="is-size-6 mb-5 lh-4_3" v-for="(text, index) in item.texts" :key="index">{{text}}</p> -->
        <p class="title is-5 lh-4_3" v-html="item.text1"></p>
        <p class="title is-5 lh-4_3" v-html="item.text2"></p>
        <!-- <p class="is-size-6 mb-5 lh-4_3" v-html="item.text3"></p> -->
        <!-- <p class="is-size-6 mb-5 lh-4_3" v-html="item.text4"></p> -->
      </div>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';

const CONTENTS = [
  {
    "src": "./static_img/img_01.png",
    "alt": "写真です",
    // "caption": "世の中の流れ",
    "subtitle": "木を見極めAIR鉋工法で<br>大阪の人々を笑顔に",
    "title": "『東野材木店は大阪で45年間、「木」の見極めと伝統在来工法で培われた卓越した技術力でたくさんの笑顔に出会いました。",
    "title2": "日本人にとって親しみのある木は、長期にわたり使用する事ができる持続可能な循環型資源です。",
    "title3": "私たちは木をお届けする会社として、木が育つのに要する時間以上に使えるものを作る責任があります。",
    "text1": "AIR鉋工法は不可能だと思われていた木の劣化部分を安全かつ短工期で再生し、<br>長寿命化と美観再生を同時に実現する唯一無二の新技術です。",
    "text2": "私たちは、大阪の人々をAIR鉋工法で笑顔にします。",
    // "text3": "木の良さ・想いを伝えるだけではなく、木が再生可能な資源であり続けるために私たちが皆様にできること。<br>大切にしてきた歴史・想いを残したまま、あの頃の喜びをもう一度。",
    // "text4": "そんな想いを胸に木部の再生に取り組んでいます。",
    // "texts": [
    //   "私たちは大阪で45年間、木材を大切にして今日まで歩んできました。<br>45年間の長い年月をかけて関わらせて頂いた施設や、木製品の老朽化を目の当たりにして、私たちは思ったのです。",
    //   "木の老朽化にも向き合いたい。",
    //   "木の良さ・想いを伝えるだけではなく、木が再生可能な資源であり続けるために私たちが皆様にできること。<br>大切にしてきた歴史・想いを残したまま、あの頃の喜びをもう一度。",
    //   "そんな想いを胸に木部の再生に取り組んでいます。",
    // ]
  }
];

export default {
  name: 'parts-about-circumstance',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>

.video-area iframe {
  width: 100%;
  height: 430px;
}

@media all and (max-width: 480px) {
  .mt-6n {
    margin-top: 0rem!important;
}

.video-area iframe {
  height: 230px;
}
}
</style>
