<template>
  <div class="">
    <h4 class="title is-4 has-text-centered has-background-dark has-text-white py-2 mb-4">{{item.title}}</h4>
    <div class="columns mb-5">
      <div class="column is-relative">
        <figure class="image">
          <img class="object-fit-contain" :src="item.src_left" :alt="item.alt_left">
        </figure>
        <span class="case-label has-text-theme-red has-background-white-transparent">Before</span>
      </div>
      <div class="column is-relative">
        <figure class="image">
          <img class="object-fit-contain" :src="item.src_right" :alt="item.alt_right">
        </figure>
         <span class="case-label has-text-white has-background-theme-red">After</span>
      </div>
    </div>
    <p class="title is-6 has-text-centered mb-6 p-3-mobile m-3-mobile" v-if="false">{{item.subtitle}}</p>
    <p class="p-3-mobile m-3-mobile" v-if="false">{{item.text}}</p>
  </div>
</template>

<script>
import config from './../config.json';

export default {
  name: 'parts-case-study',
  components: {
  },
  props: {
    showIndex: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return config.CASE_STUDY_CONTENTS;
    },
    item() {
      return this.CONTENTS[this.showIndex];
    }
  }
}
</script>

<style scoped>
.case-label {
    width: 120px;
    display: block;
    text-align: center;
    padding: 10px;
    bottom: 12px;
    right: 12px;
    position: absolute;
    font-size: 24px;
}
/* .to-bottom-left {
  top: 90% !important;
  left: 88% !important;
} */

@media (max-width:480px) {
  /* .to-bottom-left {
    font-size: 12px !important;
    } */

    .case-label {
    width: 80px;
    font-size: 1rem;
    padding: 8px;
}
}
</style>
