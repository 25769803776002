<template>
  <p class="has-text-centered has-text-left-mobile my-6 py-4 is-size-4 lh-6">
    独自開発された植物性粉体を高圧の空気で噴射し、<br
      class="is-hidden-mobile"
    />
    様々な形状に加工された木部の汚れや劣化層及び表層の腐朽菌、<br
      class="is-hidden-mobile"
    />
    古くなった既存の塗膜を除去します。<br /><br />
    粉体は植物性由来のため人体に無害で、<br
      class="is-hidden-mobile"
    />施工には事故リスクを排除した専用装置を使用しますので、安全な施工が可能です。<br /><br />
    植物性粉体の種類、空気圧を調整することで<br class="is-hidden-mobile" />
    「払う」「削る」「剥ぐ」「粗す」の四役を一台で行い<br
      class="is-hidden-mobile"
    />短工期で木材の再生を実現します。
  </p>
  <div class="columns is-hidden-mobile is-1">
    <div v-for="(item, index) in CONTENTS" :key="index">
      <div class="column">
        <figure class="image">
          <img class="object-fit-contain" :src="item.src" :alt="item.alt" />
        </figure>
        <div class="has-background-white z-1">
          <div
            class="content p-3 is-relative has-background-kanji"
            :style="`background-image:url(${item.kanji})`"
          >
            <p class="title is-1 has-text-theme-red mincho mb-3">
              {{ item.title }}
            </p>
            <p class="is-relative z-2 is-size-5 lh-5" v-html="item.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- one columnのソース ↓↓↓ -->
  <!-- <div class="columns is-gapless is-hidden-mobile" :class="((index % 2) === 1) ? 'is-flex-direction-row-reverse': ''" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column">
      <figure class="image">
        <img class="object-fit-contain" :src="item.src" :alt="item.alt">
      </figure>
    </div>
    <div class="column has-background-white mt-10p z-1"
      :class="[((index % 2) === 1) ? 'mr-7n': 'ml-7n', ((CONTENTS.length - 1) === index ? '': 'mb-6')]">
      <div class="content p-6 is-relative has-background-kanji" :style="`background-image:url(${item.kanji})`">
        <p class="title is-1 has-text-theme-red mincho mb-3">{{item.title}}</p>
        <p class="p-3 is-relative z-2 is-size-4 lh-6" v-html="item.text"></p>
      </div>
    </div>
  </div> -->

  <!-- <div class="columns is-gapless is-hidden-tablet" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column">
      <figure class="image">
        <img class="object-fit-contain" :src="item.src" :alt="item.alt">
      </figure>
    </div>
    
    <div class="column has-background-white w-90 mt-6n z-1 is-relative" style="" :style="((index % 2) === 1) ? {}: {'margin-left':'10%'}">
      <div class="content is-relative p-3 m-3-mobile has-background-kanji has-background-kanji__mobile" :style="`background-image:url(${item.kanji})`">
        <p class="title is-3 has-text-theme-red mincho">{{item.title}}</p>
        <p class="p-3 is-relative z-2" v-html="item.text"></p>
      </div>
    </div>
  </div> -->

  <div class="is-flex is-flex-wrap-wrap is-1 is-hidden-tablet">
    <div class="column" v-for="(item, index) in CONTENTS" :key="index">
      <figure class="image">
        <img class="object-fit-contain" :src="item.src" :alt="item.alt" />
      </figure>
      <div class="has-background-white z-1">
        <div
          class="content p-3 is-relative has-background-kanji"
          :style="`background-image:url(${item.kanji})`"
        >
          <p class="title is-3 has-text-theme-red mincho mb-3">
            {{ item.title }}
          </p>
          <p class="is-relative z-2 is-size-6 lh-4_2" v-html="item.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import map from "lodash/map";
//import config from './../config.json';
const CONTENTS = [
  {
    src: "./static_img/img_02-01.png",
    alt: "払う",
    title: "払う",
    kanji: "./static_img/img_kanji-01.png",
    text: "汚れや埃を刷毛のようにやさしく払う",
  },
  {
    src: "./static_img/img_02-02.png",
    alt: "削る",
    title: "削る",
    kanji: "./static_img/img_kanji-02.png",
    text: "木材の表面を数ミクロンから数ミリの単位で削る",
  },
  {
    src: "./static_img/img_02-03.png",
    alt: "剥ぐ",
    title: "剥ぐ",
    kanji: "./static_img/img_kanji-03.png",
    text: "基材を痛めることなく汚れや劣化塗膜を剥ぐ",
  },
  {
    src: "./static_img/img_02-04.png",
    alt: "粗す",
    title: "粗す",
    kanji: "./static_img/img_kanji-04.png",
    text: "塗料の付着強度を高めるために表面を粗す",
  },
];

export default {
  name: "parts-interview",
  components: {},
  data() {
    return {
      debug: false,
    };
  },
  computed: {
    CONTENTS() {
      return map(CONTENTS, (content) => {
        if (this.debug) {
          content.src = "https://bulma.io/images/placeholders/128x128.png";
        }
        return content;
      });
    },
  },
};
</script>

<style scoped>
.kanji {
  font-size: 7rem;
  top: 80%;
  left: 80%;
}
.mb-100p {
  margin-bottom: 100% !important;
}
.has-background-kanji {
  background-repeat: no-repeat;
  background-position: right 10% bottom 10%;
  background-size: 35%;
  height: 270px;
}
.has-background-kanji__mobile {
  height: 300px;
}

@media all and (max-width: 768px) {
  .column {
    width: 50%;
    flex-basis: inherit;
  }

  .has-background-kanji {
    height: 190px;
  }
}

@media all and (max-width: 480px) {
  .is-size-4 {
    font-size: 1rem !important;
  }

  .lh-6 {
    line-height: 1.3 !important;
  }

  /* .title {
    margin-bottom: 0 !important;
  } */

  .has-background-kanji {
    height: 165px;
  }

  /* 
  .content {
    padding: 3% !important;
  } */
}
</style>
