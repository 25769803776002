<template>
  <div class="" v-for="(item, index) in CONTENTS" :key="index">
    <div class="w-100">
      <div class="content">
        <p class="title is-3 mt-3 has-text-centered mincho" v-html="item.title"></p>
        <p class="is-size-4 lh-6" v-html="item.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';

const CONTENTS = [
  {
    "title": '「<span class="has-text-theme-red">大切なもの 思い出ごと</span>蘇らせます」',
    "text": "―長年使っているけど、メンテナンスの方法がわからない。<br>―汚れを綺麗にしたいけど、どこに頼めばいいかわからない。<br>東野材木店はそんなお悩みにお応えします。<br>AIR鉋工法で何十年、何百年と木に刻まれた思い出を蘇らせませんか。",
  }
];

export default {
  name: 'parts-method-targets',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
@media (max-width:480px) {
  .is-size-4 {
    font-size: 1rem !important;
  }

  .lh-6 {
    line-height: 1.75rem !important;
  }
}
</style>
