<template>
  <div class="column-wrapper">
            <figure class="image column">
        <img
          class="object-fit-cover"
          :src="require('../assets/static_img/room_img.jpg')"
        />
      </figure>
    <div class="column is-flex is-flex-direction-column">
      <h3 class="title is-3 mincho mb-5">
        <span class="has-text-theme-red">目撃せよ!シン技術</span>AIR鉋ROOM
      </h3>
        <a class="is-hidden-tablet mb-3" href="https://www.youtube.com/watch?v=234hdxmOAWU" target="_blank" rel="noopener noreferrer">
        <figure class="image">
          <img
            class="object-fit-contain"
            :src="require('../assets/static_img/youtube_thumnail.jpg')"
          />
        </figure>
            </a>
      
        <p class="mb-2 lh-4_3">
          東野材木店の社内にAIR鉋ROOMを新設しました。<br />
          主に持ち運び可能な建具・家具・玩具等の施工を行います。
        </p>
        <p class="lh-4_3">
          また、熟練の職人によるデモンストレーションや各種テスト施工も
          随時実施させて頂きますので是非、ご相談下さい。
        </p>
            <a class="is-hidden-mobile mt-5" href="https://www.youtube.com/watch?v=234hdxmOAWU" target="_blank" rel="noopener noreferrer">
        <figure class="image">
          <img
            class="object-fit-contain"
            :src="require('../assets/static_img/youtube_thumnail.jpg')"
          />
        </figure>
            </a>
    </div>
  </div>

  <!-- <div class="columns mt-5">
    <div class="column">
      <figure class="image">
        <img
          class="object-fit-contain"
          :src="require('../assets/static_img/room_img.jpg')"
        />
      </figure>
    </div>
    <a class="column" href="https://www.youtube.com/watch?v=234hdxmOAWU" target="_blank" rel="noopener noreferrer">
      <figure class="image">
        <img
          class="object-fit-contain"
          :src="require('../assets/static_img/youtube_thumnail.jpg')"
        />
      </figure>
    </a>
  </div> -->
</template>

<script>
const CONTENTS = [
  {
    src: "./static_img/chooseReason_img.jpg",
    // "alt": "写真です",
  },
];

export default {
  name: "parts-about-reason",
  components: {},
  data() {
    return {};
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    },
  },
};
</script>

<style scoped>

.object-fit-cover {
  height: 100%;
}

.column-wrapper {
  display: flex;
  height: 550px;
}
@media all and (max-width: 768px) {
  .column-wrapper {
  flex-direction: column-reverse;
  height: auto;
  }

  .object-fit-cover { 
    height: 560px;
    object-position: center;
  }

}

@media all and (max-width: 480px) {
    .object-fit-cover { 
      height: 360px;
    }

}
</style>
