<template>
  <nav class="navbar is-transparent is-absolute w-100" role="navigation" aria-label="main navigation">
    <div class="navbar-brand is-relative">
      <a class="navbar-item is-hidden-mobile pt-6" href="https://www.higashino.co.jp/">
        <img class="object-fit-contain" src="../assets/img/logo_higashino_wt.png" width="280" height="128" style="max-height: none;">
      </a>
      <a class="navbar-item centering is-hidden-tablet" href="https://www.higashino.co.jp/">
        <img class="object-fit-contain" src="../assets/img/logo_higashino_wt.png" width="280" height="128">
      </a>
  
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" :data-target="`nabvar-${uid}`">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div :id="`nabvar-${uid}`" class="navbar-menu">
      <div class="navbar-end pt-3 pr-3 is-align-items-center">
        <a class="navbar-item menu__item" :class="menu['header-class']" :href="menu.href" v-for="menu in menus" :key="menu.label">
          {{menu.label}}
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { getCurrentInstance } from 'vue';

import config from './../config.json';

export default {
  name: 'parts-header',
  setup () {
    const uid = getCurrentInstance().uid;
  // ...
    return {
      uid
    };
  },
  data() {
    return {
      menus: config.MENUS,
      show: true,
    }
  },
}
</script>

<style scoped>
.navbar.is-transparent {
  background-color: transparent;
  background-image: none;
}
.navbar-burger {
  color: #fff;
  z-index: 99;
}
.navbar-item {
  color: #fff;
  font-weight: bold;
  margin-right: 1em;
}

/* ボタン */
.consultation-btn {
  transition: .4s;
}
.navbar-item.consultation-btn:hover {
  opacity: 0.8;
}

.navbar-item:hover {
  color: rgb(219, 219, 219) !important;
}

.button.is-transparent {
  background-color: transparent;
  border-color: #fff;
}

.button {
  padding: 25px 70px;
  transition: .4s;
}

@media screen and (max-width: 1280px) { 
  .navbar-item {
    margin-right: 0;
  }

  .button { 
    padding: 25px 30px;
  }
}

@media screen and (max-width: 1023px) {
/* .navbar-item {
  color: unset;
} */

.navbar-end {
 padding: 8% 5%;
}

.consultation-btn {
  padding: 0.5em 0 0.5em 1rem;
  border: none;
}
.navbar-menu {
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.has-text-left-mobile {
  text-align: left;
}
}
</style>