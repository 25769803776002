<template>
  <div class="column-wrapper">
    <h3 class="title is-2 mt-3 has-text-centered mincho">
      AIR鉋工法の<span class="has-text-theme-red is-block-mobile"
        >価値 - 1</span
      >
    </h3>
    <p class="has-text-centered my-6 py-4 is-size-4 lh-6">
      AIR鉋は従来の「洗う」「削る」工法では取り除けなかった<br class="" />
      「痩せ部」等の劣化層や脆弱層を取り除くことができる新技術です。
    </p>
    <ul class="column-list">
      <li class="column-list__item dashed">
        <div class="column-title has-bg-brown">劣化した<br />木材</div>
        <div class="column-inner">
          <figure class="image">
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature01.jpg')"
            />
          </figure>
        </div>
      </li>
      <li class="column-list__item">
        <div class="column-title has-bg-yellow">
          「洗う」従来工法<br />「削る」
        </div>
        <div class="column-inner">
          <p class="has-text-centered column-text mb-5">
            従来の湿式・乾式工法では、木地ややせ部分の劣化層や脆弱層が取り除けない。
          </p>
          <figure class="image">
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature02.jpg')"
            />
          </figure>
        </div>
      </li>
      <li class="column-list__item">
        <div class="column-title has-bg-red">
          特許・新技術
          <br />AIR鉋工法
        </div>
        <div class="column-inner">
          <p class="has-text-centered column-text mb-5">
            どのような凹凸でも<br
              class="is-hidden-tablet"
            />ミクロン単位で削れます。
          </p>
          <figure class="image">
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature03.jpg')"
            />
          </figure>
        </div>
      </li>
    </ul>
    <div
      class="
        title
        is-size-5
        has-text-centered has-bg-red has-text-white
        p-4
        mt-6
      "
    >
      AIR鉋はミクロン単位で劣化層、脆弱層を削り取り、強い木地を露出させます。
    </div>
  </div>

  <div class="column-wrapper">
    <h3 class="title is-2 mt-3 has-text-centered mincho">
      AIR鉋工法の<span class="has-text-theme-red is-block-mobile"
        >価値 - 2</span
      >
    </h3>
    <p class="has-text-centered my-6 py-4 is-size-4 lh-6">
      AIR鉋は強い木地層を露出させ、エアーポケットを生成します<span
        class="is-size-6"
        >(顕微鏡写真参照)</span
      ><br />
      エアーポケットは塗料の密着面積と顔料含浸量を増加させる働きがあります。
    </p>
    <ul class="column-list">
      <li class="column-list__item">
        <div class="column-title has-bg-yellow">「洗う」従来工法「削る」</div>
        <div class="column-inner">
          <figure class="image image-column">
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature04.jpg')"
            />
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature05.jpg')"
            />
          </figure>
        </div>
      </li>
      <li class="column-list__item">
        <div class="column-title has-bg-red">
          特許・新技術
          <br />AIR鉋工法
        </div>
        <div class="column-inner">
          <figure class="image">
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature06.jpg')"
            />
          </figure>
        </div>
      </li>
    </ul>
    <div
      class="
        title
        is-size-5
        has-text-centered has-bg-red has-text-white
        p-4
        mt-6
      "
    >
      AIR鉋は、塗装前の高品質な下地処理を短時間で行うことが出来ます。
    </div>
  </div>

  <div class="">
    <h3 class="title is-2 mt-3 has-text-centered mincho">
      AIR鉋工法の<span class="has-text-theme-red is-block-mobile"
        >価値 - 3</span
      >
    </h3>
    <p class="has-text-centered my-6 py-4 is-size-4 lh-6 is-size-4">
      エアーポケットによる塗料(保護剤等)の密着面積と顔料浸透量の増加は、<br
        class="is-hidden-tablet"
      />
      木材に丈夫な皮膜を作ることになり、塗装後の耐久性が大幅に向上します。
    </p>
    <ul class="column-list">
      <li class="column-list__item">
        <div class="column-inner">
          <figure class="image">
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature07.jpg')"
            />
          </figure>
          <!-- <figure class="image is-hidden-tablet">
            <img
              class="object-fit-contain"
              :src="require('../assets/static_img/feature07_sp.jpg')"
            />
          </figure> -->
        </div>
      </li>
    </ul>
    <div
      class="
        title
        is-size-5
        has-text-centered has-bg-red has-text-white
        p-4
        mt-6
      "
    >
      AIR鉋は工法+保護塗装は木材の寿命を延ばします。
    </div>
  </div>
</template>

<script>
// const CONTENTS = [
//   {
//     "src": "./static_img/feature01.jpg",
//   },
//   {
//     "src": "./static_img/feature02.jpg",
//   },
//   {
//     "src": "./static_img/feature03.jpg",
//   },
//   {
//     "src": "./static_img/feature04.jpg",
//   }
// ];

// export default {
//   name: 'parts-room',
//   components: {
//   },
//   data() {
//     return {
//     };
//   },
//   computed: {
//     CONTENTS() {
//       return CONTENTS;
//     }
//   }
// }
</script>

<style scoped>
.column-wrapper {
  margin-bottom: 200px;
}

.column-text {
  font-size: 24px;
}
.column-title {
  text-align: center;
  color: #fff;
  padding: 20px 10px;
  border-radius: 9px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 35px;
  display: flex;
  height: 90px;
  flex-direction: column;
  justify-content: center;
}
.column-inner {
  display: flex;
  flex-direction: column;
}

.column-list {
  display: flex;
}

.column-list__item {
  /* flex: 1; */
  border-right: 1px dashed #72151e;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column-list__item:last-child {
  border-right: none;
}

.image-column {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.image-column img {
  width: 48%;
}

/* .dashed {
  background-image : linear-gradient(to right, #72151E, #72151E 2px, transparent 2px, transparent 8px);  
  background-size: 8px 1px;          
  background-position: right top;  
  background-repeat: repeat-y;       
} */

.has-bg-red {
  background: #72151e;
}

.has-bg-brown {
  background: #947864;
}
.has-bg-yellow {
  background: #9d7833;
}

@media all and (max-width: 1500px) {
  /* .column-height {
    height: 230px;
  } */

  .column-title {
    padding: 15px;
    font-size: 18px;
    height: 65px;
  }

  .column-text {
    font-size: 16px;
  }
}

@media all and (max-width: 768px) {
  /* .column-list {
    flex-direction: column;
  } */

  .column-list__item {
    margin-bottom: 80px;
    /* border-right: none; */
    padding: 0 5px;
  }
  /* 
  .column-height {
    height: auto;
  } */

  .column-title {
    padding: 3px;
    font-size: 12px;
  }

}

@media all and (max-width: 480px) {
  .is-size-4 {
    font-size: 1rem !important;
  }

  .lh-6 {
    line-height: 1.6 !important;
  }

  .title {
    margin-bottom: 0 !important;
  }

  .title.is-2 {
    font-size: 1.8rem;
  }

  .column-wrapper {
    margin-bottom: 100px;
  }

  .column-text {
    font-size: 12px;
  }

  .column-title {
    margin-bottom: 10px;
  }
  /* s */
}
</style>
