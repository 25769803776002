<template>
  <iframe frameborder="0" scrolling="no" src="../form_air/index.php" id="FormTool_f050" class="mt-6" width="100%"></iframe> 
</template> 
  
<script>
export default {
  name: 'parts-contact-iframe',
  data() {
    return {
    }
  },
}
</script>
